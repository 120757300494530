import React, { useEffect, useState } from 'react'
import axios from 'axios'
import socket from '../Components/socket'
import { apiRequest } from '../store/allApi'

const Referralhis = () => {
  const [user, setUser] = useState()
  const [isLoading, setIsLoading] = useState(false)

   // BASE URL
 
   const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
   const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
   const nodeMode = process.env.NODE_ENV;
   if (nodeMode === "development") {
 
     var baseUrl = beckendLocalApiUrl;
   } else {
     baseUrl = beckendLiveApiUrl;
 }
 // +++++++++++++++++++++++++++++ 

  const role = async () => {
    socket.emit('getprofile')

    const access_token = localStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    try {
              const res = await apiRequest(`me`, "GET", null, headers)
              if (res.data) {
                 setUser(res.data);
      
            //Allgames(res.data._id)
            Allgames(res.data.referral_code)
            // window.location.reload()
              }
          } catch (e) {
              // setLoading(false)
          
          }
          finally {
          }
  }

  const [cardData, setGame] = useState([])
  // console.log(cardData)

  const Allgames = async id => {
    const access_token = localStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    setIsLoading(true)
    
     try {
        const res = await apiRequest(`referral/code/winn/${id}`, "GET", null, headers)
        if (res) {
          setGame(res.data)
        setIsLoading(false)
        }
    } catch (e) {
        // setLoading(false)
    
    }
    finally {
    }
  }

  const dateFormat = e => {
    const date = new Date(e)
    const newDate = date.toLocaleString('default', {
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      hour12: true,
      minute: 'numeric'
    })
    return newDate
  }
  useEffect(() => {
    role()
    //eslint-disable-next-line
  }, [])

  if (cardData == undefined) {
    return null
  }

  return (
    <div>
<div className=' mt-1  Orher_page_main_section'>
   <div>
            {isLoading ? (
              <div className='loaderReturn' style={{ zIndex: '99' }}>
                <img
                  src={'/images/LandingPage_img/loader1.gif'}
                  style={{ width: '100%' }}
                />
              </div>
            ) : cardData && cardData.length == 0 ? (
              <div className='text-center not_found_box_main'>
                <img
                  src='./images/not-found.gif'
                  alt='no data'
                  
                />
                <div className='mt-2'>
                  <h3 className='font-weight-bold'>No Referal History</h3>
                  <p>You have no Referal history yet.</p>
                </div>
              </div>
            ): (
              cardData &&
              cardData.map(card => {
                return (
                  <>
                    <div className='other_pages_card_main' key={card?._id}>
                      <div>
                        <h3>Referral Earning</h3>
                        <p> Earned by:{card?.earned_from?.Name}</p>
                      </div>

                      {/* time and date */}
                      <div className='transaction_right_side_main'>
                        <div className='card_rupee '>
                          <div className='d-flex float-right font-8'>
                            <div className='text-danger'>{card?.losestatus}</div>
                            <div className='text-success'>{card?.winstatus}</div>
                            <picture className='ml-1 mb-1'>
                              <span>₹</span>
                            </picture>
                            <h3 className='pl-1'>{card?.amount}</h3>
                          </div>
                        </div>
                        <p>
                          {' '}
                          Closing Balance: <b> {card?.closing_balance}</b>
                        </p>
                        <div className='card_date'>
                          <p>{dateFormat(card?.createdAt).split(',')[0]}</p>
                          <p>{dateFormat(card?.createdAt).split(',')[1]}</p>
                        </div>
                      </div>
                    </div>
                   
                  </>
                )
              })
            )}
          </div>
        </div>
    </div>
  )
}

export default Referralhis
