const { io } = require('socket.io-client')

const socket = io('https://kbkd.khiladiludo.com', {
  transports: ['websocket']
})

socket.on('connect_error', () => {
  socket.io.opts.transports = ['polling', 'websocket']
})
export default socket
